<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统设置</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课程推荐</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:5px">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="resourceName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <span title="培训类型" class="searchboxItem ci-full flexcc">
              <span class="itemLabel">培训类型:</span>
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </span>
            <div title="资源提供者" class="searchboxItem ci-full">
              <span class="itemLabel" style="width:6rem">资源提供者:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="权重排序" class="searchboxItem ci-full">
              <span class="itemLabel">权重排序:</span>
              <el-select v-model="weightSort" placeholder="请选择" size="small">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="100px"/>
              <el-table-column
                label="课程名称"
                align="left"
                prop="courseName"
                show-overflow-tooltip
                width="280"
              />
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                prop="trainTypeNamePath"
                width="140"
              >
                <template slot-scope="scope">{{scope.row.trainTypeNamePath || '--'}}</template>
              </el-table-column>
              <el-table-column label="行业类型" align="left" show-overflow-tooltip>
                <template slot-scope="scope">{{scope.row.industryNamePath || '--'}}</template>
              </el-table-column>
              <el-table-column label="岗位类型" align="left" show-overflow-tooltip>
                <template slot-scope="scope">{{scope.row.postName || '--'}}</template>
              </el-table-column>
              <el-table-column label="职业/工种" align="left" show-overflow-tooltip>
                <template slot-scope="scope">{{scope.row.occupationNamePath || '--'}}</template>
              </el-table-column>
              <el-table-column label="培训等级" align="left" show-overflow-tooltip>
                <template slot-scope="scope">{{scope.row.trainLevelName || '--'}}</template>
              </el-table-column>
              <el-table-column label="学时" align="right" prop="lessonNum" show-overflow-tooltip />
              <el-table-column
                label="资源提供者"
                align="left"
                prop="compName"
                show-overflow-tooltip
                width="280"
              />
              <el-table-column label="权重" align="right" prop="weight" show-overflow-tooltip />
              <el-table-column label="操作" align="center" width="100px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handleSetWeight(scope.row.courseId,scope.row.weight)"
                  >设置权重</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog
      title="设置权重"
      :visible.sync="DialogVisible"
      width="500px"
      center
      :before-close="doClose"
    >
      <el-form label-width="80px" ref="Form" :model="Form" class="docsDialog" :rules="rules">
        <el-form-item label="权重" prop="weightNum">
          <el-input v-model="Form.weightNum" placeholder="请输入-10000至10000之间的整数"></el-input>
        </el-form-item>
      </el-form>
      <p style="padding: 0 0 0 80px">
        说明:权重值越大排名越靠前,权重值范围为
        <span style="color: red;font-size:16px">-10000</span>至
        <span style="color:red;font-size:16px">10000</span>之间的整数
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFalse('Form')">取 消</el-button>
        <el-button class="bgc-bv" @click="doSure('Form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
export default {
  name: "operate/CourseRecommen",
  components: {
    Empty,
    PageNum,
    tree
  },
  mixins: [List],
  data() {
    let reg = /^-?(?:[1-9](?:\d{0,3})?)$|^-?(0|10000)$/;
    var weightNumCheck = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入权重值"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入-10000至10000之间的整数值"));
      } else {
        callback();
      }
    };
    return {
      compId: "",
      resourceName: "",
      weightSort: "1",
      CompanyList: [],
      params: {},
      ruleForm: {
        Trainingtype: ""
      },
      Form: {
        weightNum: ""
      },
      rules: {
        weightNum: [
          { required: true, validator: weightNumCheck, trigger: "blur" }
        ]
      },
      options: [
        {
          value: "1",
          label: "由低到高"
        },
        {
          value: "2",
          label: "由高到低"
        }
      ],
      DialogVisible: false
    };
  },
  created() {},
  computed: {},
  methods: {
    /* tree */
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    // 资源提供者
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.resourceName) {
        params.resourceName = this.resourceName;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.weightSort) {
        params.weightSort = this.weightSort;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/run/course/queryRecommendedCourse",
        params,
        pageNum
      });
    },
    handleSetWeight(courseId, weight) {
      this.DialogVisible = true;
      this.courseId = courseId;
      this.Form.weightNum = weight;
    },
    doSure(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$post("/run/course/updateCourseWeight", {
            courseId: this.courseId,
            weight: this.Form.weightNum
          })
            .then(ret => {
              this.$message({
                message: "成功",
                type: "success"
              });
              this.DialogVisible = false;
              this.getData();
            })
            .catch(err => {
              return;
            });
        }
      });
    },
    doClose() {
      this.DialogVisible = false;
      this.Form.weightNum = "";
      this.$refs["Form"].clearValidate("weightNum");
    },
    dialogFalse(formName) {
      this.DialogVisible = false;
      this.Form.weightNum = "";
      this.$refs[formName].resetFields();
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    }
  }
};
</script>
<style lang="less">
.searchbox {
  padding: 0;
}
</style>
